import Vue from 'vue'
import VueSession from "vue-session";
import jwt from "jsonwebtoken";
Vue.use(VueSession);

const func = {
  checkMenu: (path) => {
    // Get jwt key
    let privateKey = process.env.VUE_APP_JWT_KEY
    Vue.prototype.$session.start()
    const payload = Vue.prototype.$session.get('privileges')
    // ทำการยืนยันความถูกต้องของ data
    let result
    if (payload) {
      jwt.verify(payload, privateKey, function (error, decoded) {
        result = decoded.privileges
      })
      if (result){
        const check = result.filter(item => item.menu_url === path)
        return check
      }
    }
    return result
  },
  generateReportDate: () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()

    return yyyy + mm + dd + today.getHours() + today.getMinutes() + today.getMilliseconds()
  }
}
/*export default function checkMenu(path) {
  // Get jwt key
  let privateKey = process.env.VUE_APP_JWT_KEY
  Vue.prototype.$session.start()
  const payload = Vue.prototype.$session.get('privileges')
  // ทำการยืนยันความถูกต้องของ data
  let result
  if (payload) {
    jwt.verify(payload, privateKey, function (error, decoded) {
      result = decoded.privileges
    })
    if (result){
      const check = result.filter(item => item.menu_url === path)
      return check
    }
  }
  return result
}*/

export default func;
