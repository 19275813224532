import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchMenu() {
  return request({
    url: baseURL + '/master/menu',
    method: 'get'
  })
}

export function fetchMenuPrivList() {
  return request({
    url: baseURL + '/master/menu/privilege',
    method: 'get'
  })
}

export function fetchProvince() {
  return request({
    url: baseURL + '/master/province',
    method: 'get'
  })
}

export function fetchBranch() {
  return request({
    url: baseURL + '/master/branch',
    method: 'get'
  })
}

export function fetchRole() {
  return request({
    url: baseURL + '/master/role',
    method: 'get'
  })
}

export function fetchTransportOffice() {
  return request({
    url: baseURL + '/master/transport-office/list',
    method: 'get'
  })
}

export function fetchTransportOfficeWithProvince(param) {
  return request({
    url: baseURL + '/master/transport-office',
    method: 'get',
    params: param
  })
}

export function fetchFilter(params) {
  return request({
    url: baseURL + '/master/filter/step',
    method: 'get',
    params: params
  })
}

export function fetchContractType() {
  return request({
    url: baseURL + '/master/contract-type',
    method: 'get'
  })
}

export function fetchCauseStep(params) {
  return request({
    url: baseURL + '/master/cause/step',
    method: 'get',
    params: params
  })
}

export function fetchAccountStep(params) {
  return request({
    url: baseURL + '/account/stepList',
    method: 'get',
    params: params
  })
}
