//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchFilter} from "@/api/master";
import {fetchSkipStepList} from '@/api/skipStep'
import {Message} from "element-ui";
import func from "@/utils/decoded";

const fields = [
	{ key: 'contract_id', label:'เลขที่สัญญา', _style:'min-width:100px' },
	{ key:'name_customer', label:'ชื่อ-สกุล', _style:'min-width:150px;'},
	{ key: 'start_date_contract', label:'วันที่เริ่มทำสัญญา', _style:'min-width:100px;' },
	{ key: 'balance', label:'ยอดเงินคงเหลือ', _style:'min-width:100px; text-align: right;' },
  { key: 'status_id', label:'สถานะ', _style:'min-width:100px;' },
  { key: 'process_day', label:'เวลาดำเนินการ(วัน)', _style:'min-width:50px; text-align: right;' },
	{
		key: 'show_details',
		label: 'รายละเอียด',
		_style: 'min-width:150px; text-align: center;',
		sorter: false,
		filter: false
	}
]

export default {
  name: 'SkipStep',
  data() {
    return {
      options: [],
      show: true,
			isCollapsed: true,
			items: [],
			fields,
      filterParams: {
				stepId: 16
			},
      searchParams: {
				keyword: '',
				startDate: '',
				endDate: '',
				filter: 0
			},
      privilege: {read: false, write: false, report: false}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },

  async mounted() {
    this.$store.state.loading = true
    try {
      await new Promise((resolve, reject) => {
        fetchFilter(this.filterParams).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.options = data
						console.log('filter : ', this.options);
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
      })
      await this.getSkipStepList()
    } finally {
      this.$store.state.loading = false
    }

    const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
  },

  methods: {
    async getSkipStepList() {
      console.log('searchParams : ', this.searchParams);
      await fetchSkipStepList(this.searchParams).then(res => {
        this.items = []
        const check = res.header;
				const data = res.body;
        if (check.error === 'N') {
          for (let item of data) {
            if(item.cur_step === 1) {
              if(item.status_id === 0) {
                item.status_id = 'ตรวจสอบเอกสาร[รอดำเนินการ]'
              } else if(item.status_id === 1) {
                item.status_id = 'ตรวจสอบเอกสาร[ไม่เรียบร้อย]'
              } else if(item.status_id === 2) {
                item.status_id = 'ตรวจสอบเอกสาร[เรียบร้อย]'
              }
            } else if(item.cur_step === 2) {
              if(item.status_id === null || item.status_id === 0) {
                item.status_id = 'วางชุดโอน[รอดำเนินการ]'
              } else if(item.status_id === 1) {
                item.status_id = 'วางชุดโอน[ไม่เรียบร้อย]'
              } else if(item.status_id === 2) {
                item.status_id = 'วางชุดโอน[เรียบร้อย]'
              }
            } else if(item.cur_step === 3) {
              if(item.status_id === null || item.status_id === 0) {
                item.status_id = 'รับเล่มทะเบียน[รอดำเนินการ]'
              } else if(item.status_id === 1) {
                item.status_id = 'รับเล่มทะเบียน[ไม่เรียบร้อย]'
              } else if(item.status_id === 2) {
                item.status_id = 'รับเล่มทะเบียน[เรียบร้อย]'
              }
            }
          }
          this.items = data;
					console.log('items : ', this.items);
        }
      }).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
    },


    getBadge (status) {
			switch (status) {
        case 'ตรวจสอบเอกสาร[เรียบร้อย]': return 'success'
        case 'ตรวจสอบเอกสาร[ไม่เรียบร้อย]': return 'danger'
				case 'วางชุดโอน[เรียบร้อย]': return 'success'
				case 'วางชุดโอน[ไม่เรียบร้อย]': return 'danger'
        case 'รับเล่มทะเบียน[เรียบร้อย]': return 'success'
				case 'รับเล่มทะเบียน[ไม่เรียบร้อย]': return 'danger'
        case 'ตรวจสอบเอกสาร[รอดำเนินการ]': return 'warning'
        case 'วางชุดโอน[รอดำเนินการ]': return 'warning'
        case 'รับเล่มทะเบียน[รอดำเนินการ]': return 'warning'
				default: return 'primary'
			}
		},
    rowClickDetail(item){
      this.$router.push({path: `/skipStep/${item.contract_id}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    }
  }
}
