import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchSkipStepList(params) {
    return request({
        url: baseURL + '/skipStep/list',
        method: 'GET',
        params: params
    })
}

export function fetchSkipStepDetail(params) {
    return request({
        url: baseURL + '/skipStep/detail',
        method: 'GET',
        params: params
    })
}

export function SkipStepSave(data) {
    return request({
        url: baseURL + '/skipStep/save',
        method: 'POST',
        data
    })
}